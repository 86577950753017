<template>
    <Box>
  
      <AddRow v-if="this.showDialog == 'addDialog'" :itemId="this.selectedId" v-on:withButtonPressed="withButtonPressed" v-on:withValue="withValue"></AddRow>
      <UpdateRow v-if="this.showDialog == 'updateDialog'" :itemId="this.selectedId" v-on:withButtonPressed="withButtonPressed" v-on:withValue="withValue"></UpdateRow>
      <RemoveRow v-if="this.showDialog == 'removeDialog'" :itemId="this.selectedId" v-on:withButtonPressed="withButtonPressed" v-on:withValue="withValue"></RemoveRow>
      <RestoreRow v-if="this.showDialog == 'restoreDialog'" :itemId="this.selectedId" v-on:withButtonPressed="withButtonPressed" v-on:withValue="withValue"></RestoreRow>
      <DetailsRow v-if="this.showDialog == 'detailsDialog'" :itemId="this.selectedId" v-on:withButtonPressed="withButtonPressed" v-on:withValue="withValue"></DetailsRow>
      <WorkingDisplayRow v-if="this.showDialog == 'workingDialog'" :itemId="this.selectedId" v-on:withButtonPressed="withButtonPressed" v-on:withValue="withValue" :isAdmin="isAdmin"></WorkingDisplayRow>
      
      <Row>
        <Column :width="15">
          <Box class="rc-border-thin" :key="redraw + 1">
            <Row v-for="(row, rowIndex) in headerData" :key="rowIndex" >
              <CellFactory 
                :rowData="row.data"
                :showMissingRequirements="showMissingRequirements"
                v-on:withEnterPressed="withEnterPressed"
                v-on:withValue="withValue"
                v-on:withButtonPressed="withButtonPressed"
                  />
            </Row>
          </Box>
        </Column>
      </Row>

      <Row >
        <Column :width="15" >
          <Box :key="redraw" class="full-height-scrollable-div rc-border-thin">
            <Row v-for="(row, rowIndex) in viewData" :key="rowIndex" >
              <CellFactory 
                :rowData="row.data"
                :showMissingRequirements="showMissingRequirements"
                v-on:withEnterPressed="withEnterPressed"
                v-on:withValue="withValue"
                v-on:withButtonPressed="withButtonPressed"
                  />
            </Row>
          </Box>
        </Column>
      </Row>
  
      <Row>
        <Column :width="15">
          <Box class="rc-border-thin" :key="redraw + 2">
            <Row v-for="(row, rowIndex) in footerData" :key="rowIndex" >
              <CellFactory 
                :rowData="row.data"
                :showMissingRequirements="showMissingRequirements"
                v-on:withEnterPressed="withEnterPressed"
                v-on:withValue="withValue"
                v-on:withButtonPressed="withButtonPressed"
                  />
            </Row>
          </Box>
        </Column>
      </Row>
  
    </Box>
    
  </template>
  
  <script>
  
import { mapActions, mapGetters } from 'vuex';
  
import ConnectionUtils from '@/utils/ConnectionUtils.js';
import ConstUtils from '@/utils/ConstUtils.js';
import ContentUtils from '@/utils/ContentUtils.js';
import StringUtils from '@/utils/StringUtils.js';
  
import MC from "@/domain/session/MC.js";

import Box from "@/portals/shared/library/box/Box.vue";
import Column from "@/portals/shared/library/box/Column.vue";
import Row from "@/portals/shared/library/box/Row.vue";
  
import ListViewModel from "./ListViewModel.js";
import ListViewUtils from "./ListViewUtils.js";
  
import CellFactory from "@/portals/shared/cell/CellFactory.vue";
import AddRow from "./add/AddRow.vue";
import DetailsRow from './details/DetailsRow.vue';
import RemoveRow from "./remove/RemoveRow.vue";
import RestoreRow from "./restore/RestoreRow.vue";
import UpdateRow from "./update/UpdateRow.vue";

import WorkingDisplayRow from '../../../../../shared/views/working/WorkingDisplayRow.vue';

export default {
    name: "checklist-list-view",
    components: {
    Column, Box, Row,
    CellFactory,
    DetailsRow,
    AddRow, UpdateRow,
    WorkingDisplayRow,
    RemoveRow,
    RestoreRow,
},
    props: {
      adminDisplay: {type: Boolean, default: false},
      isAdmin: {type: Boolean, default: false},
    },
    data() {
      return {
        redraw: 1,
        
        MC: new MC(),
        Strings: StringUtils,
        Contents: ContentUtils,
        Consts: ConstUtils,
        
        CellFactory: CellFactory,
        
        viewModel: null,
        viewData: [],
        footerData: [],
        headerData: [],
        showMissingRequirements: false,
        selectedId: "",
        showDialog: "",

        ListViewModel: ListViewModel,
        ListViewUtils: ListViewUtils,
      };
    },
    computed: {
      ...mapGetters([
                     'auth_connected',
                     'auth_socket_status',
                     'signin_event',
                     'auth_client',
                     'domain',
                     ]),
    },
    watch: {
      auth_connected() {
        ConnectionUtils.sendSignInEvent(this);
      },
      auth_socket_status() {
        ConnectionUtils.displayStatus(this);
      },
      signin_event() {
        ConnectionUtils.createDomainSession(this);
      },
      $route (to, from){
        if (to && from) {
          if (to.query["action"] === "addDialog") {
            this.showDialog = "addDialog";

          } else if (to.query["action"] === "updateDialog") {
            this.selectedId = to.query["checklistId"];
            this.showDialog = "updateDialog";

          } else if (to.query["action"] === "removeDialog") {
            this.selectedId = to.query["checklistId"];
            this.showDialog = "removeDialog";

          } else if (to.query["action"] === "restoreDialog") {
            this.selectedId = to.query["checklistId"];
            this.showDialog = "restoreDialog";

          } else if (to.query["action"] === "detailsDialog") {
            this.selectedId = to.query["checklistId"];
            this.showDialog = "detailsDialog";

          } else if (to.query["action"] === "displayDialog") {
            this.selectedId = to.query["checklistId"];
            this.showDialog = "displayDialog";

          } else if (to.query["action"] === "workingDialog") {
            this.showDialog = "workingDialog";

          } else {
            if (StringUtils.isNotEmpty(this.showDialog)) {
              this.showDialog = "";

            } else {
              this.reloadPage();
            }
          }
        }
      }
    },
    mounted: function () {
      this.viewModel = new ListViewModel(this);
      this.viewModel.withStatePageLoading();
      this.viewModel.clearAndBuildTableData();
      this.viewModel.start();
      ConnectionUtils.ensureConnection(this);
    },
    methods: {
      ...mapActions([
                     'AuthStorage_updateCompany',
                     'addListenerAction',
                     'removeListenerAction',
                     'updateDomain',
                     ]),
  
      start: function() {
        ConnectionUtils.ensureCompanyLoaded(this, "checklists");
        this.viewModel.withStateContentLoading();
        this.viewModel.clearAndBuildTableData();
        this.viewData = this.viewModel.viewData();
        this.paint();
        this.viewModel.loadItems(false);
      },
  
      displayProgress: function(msg) {
        this.callback(msg);
      },

      callback: function(msg) {
        var row = this.viewModel.findRowProgress();
        if (row) {
          row.withMessage(msg);
        }
        this.paint();
      },
      
      model: function() {
        return this.viewModel;
      },
      
      showDialogIfRequired: function() {
        var query = Object.assign({}, this.$route.query);
        if (query["action"]) {
          this.showDialog = query["action"];
          this.selectedId = query["checklistId"];
        } 
      },

      urlQueryParams() {
        var query = Object.assign({}, this.$route.query);
        if (query == null) {
          query = {};
        }
        return query;
      },

      addQueryParams(valueData, key) {
        var query = this.urlQueryParams();
        this.addQueryParamsToRouter(query, valueData, key);
      },

      addQueryParamsToRouter(query, valueData, key) {
        const value = valueData["value"];
        if (value && value != "null") {
          query[key] = value;
          if (key === "pageSize") {
            delete query.page
          }
        } else {
          delete query[key];
        }
        this.$router.push({ query });
      },

      withEnterPressed(keyData) {
        if (keyData["title"]) {
          keyData["field"] = "Search"
          this.withButtonPressed(keyData);
        }
      },

      withValue: function(valueData) {
        this.model().withValue(valueData);
        
        if (valueData["field"] === "PageSize") {
          this.model().withPageSize(valueData, "checklist");
          this.addQueryParams(valueData, "pageSize");
        }
        
        if (valueData["field"] === "PageIndex") {
          this.addQueryParams(valueData, "page");
        }
        
        if (valueData["field"] === "Search") {
          this.model().withSearchText(valueData);
        }
      },
      
      withButtonPressed: function(buttonData) {
        this.model().withButtonPressed(buttonData);

        if (buttonData["field"] === "Add") {
          const query = this.urlQueryParams();
          query["action"] = "addDialog";
          this.$router.push({ query });
        }
        
        if (buttonData["field"] === "Update") {
          const query = this.urlQueryParams();
          query["action"] = "updateDialog";
          query["checklistId"] = this.Contents.baseEncode(buttonData["id"]),
          this.$router.push({ query });
        }

        if (buttonData["field"] === "Remove") {
          const query = this.urlQueryParams();
          query["action"] = "removeDialog";
          query["checklistId"] = this.Contents.baseEncode(buttonData["id"]),
          this.$router.push({ query });
        }

        if (buttonData["field"] === "Restore") {
          const query = this.urlQueryParams();
          query["action"] = "restoreDialog";
          query["checklistId"] = this.Contents.baseEncode(buttonData["id"]),
          this.$router.push({ query });
        }

        if (buttonData["field"] === "Details") {
          const query = this.urlQueryParams();
          query["action"] = "detailsDialog";
          query["checklistId"] = this.Contents.baseEncode(buttonData["id"]),
          this.$router.push({ query });
        }

        if (buttonData["field"] === "Refresh") {
          this.reloadPage();
        }

        if (buttonData["field"] === "NextPage") {
          const query = this.urlQueryParams();
          if (query.page) {
            query.page = (Number(query.page) + 1) + "";
          } else {
            query.page = "2";
          }
          if (query.page <= this.model().pages().length) {
            this.$router.push({ query });
          }
        }

        if (buttonData["field"] === "PreviousPage") {
          const query = this.urlQueryParams();
          if (query.page) {
            query.page = (Number(query.page) - 1) + "";
            if (query.page <= 0) {
              query.page = "1";
            }
            this.$router.push({ query });
          }
        }

        if (buttonData["field"] === "Close") {
          let query = this.urlQueryParams();
          query = this.deleteDetails(query);
          this.$router.push({ query });
          if (buttonData["removed"] || buttonData["refresh"] === "true") {
            this.reloadPage();
          }
        }

        if (buttonData["field"] === "Clear") {
          this.model().withSearchText("");
          buttonData["value"] = "";
          this.addQueryParams(buttonData, "search");
        }

        if (buttonData["field"] === "Search") {
          buttonData["value"] = this.model().searchText();
          const queryParams = this.urlQueryParams();
          delete queryParams["page"];
          this.addQueryParamsToRouter(queryParams, buttonData, "search");
        }

        if (buttonData["field"] === "Full") {
          var fullParams = {
            id: buttonData["id"],
          };
          this.$router.push({
            name: ConstUtils.ROUTES.VEHICLE.DETAILS,
            params: fullParams,
          });
        }

        if (buttonData["field"]=== "State") {
          this.addQueryParams(buttonData, "state");
        }

        if (buttonData["field"]=== "Select") {
          this.$emit("withButtonPressed", buttonData);
        }

      },
      
      deleteDetails(query) {
        delete query.action;
        delete query.checklistId;
        return query;
      },

      reloadPage: function() {
        this.viewModel.withStateContentLoading();
        this.viewModel.clearAndBuildTableData();
        this.viewModel.loadItems();
      },

      paint: function() {
        this.redraw++;
      },

    },
  }
</script>